<template>
    <div class="p-3">
        <div class="text-center">
            <el-radio-group v-model="isMappingCompleted" @change="loadLands(true)">
                <el-radio-button :label="false">待测绘</el-radio-button>
                <el-radio-button :label="true">已测绘</el-radio-button>
            </el-radio-group>
        </div>

        <el-card shadow="never" class="mt-3">
            <div class="row no-gutters">
                <div class="col">
                    <el-input :value="regionName" readonly placeholder="土地行政区划" style="width: 280px;">
                        <template #append>
                            <el-button @click="selectRegionDialogVisible=true">选择</el-button>
                        </template>
                    </el-input>
                    <search-box v-model="key" class="ml-2" style="width: 280px;" @search="loadLands(true)" />
                </div>
            </div>
            <el-table :data="lands.data" class="mt-2">
                <el-table-column label="名称/编号" sortable>
                    <template #default="scope">
                        <div>
                            <router-link :to="'/land/detail?id='+scope.row.id" target="_blank">
                                {{scope.row.name}}
                            </router-link>
                        </div>
                        <div class="text-info">{{scope.row.code}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="typeName" label="类型" sortable width="160" />
                <el-table-column prop="regionName" label="土地行政区划" sortable width="160" />
                <el-table-column prop="area" label="面积" sortable width="140" />
                <el-table-column label="承包日期" width="140">
                    <template #default="scope">
                        <div>{{$moment.ld(scope.row.beginContractDate)}}</div>
                        <div>{{$moment.ld(scope.row.endContractDate)}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="70">
                    <template #default="scope">
                        <el-button type="text" @click="showMappingDialog(scope.row)">测绘</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="lands.totalRecords"
                :current-page="landsPagination.currentPage" :page-size="landsPagination.pageSize" class="mt-3"
                @current-change="landsPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="mappingDialogVisible" title="测绘" :close-on-click-modal="false" fullscreen>
            <div style="margin: -16px;">
                <el-amap v-if="mappingDialogVisible" :plugin="plugin" :center="mapCenter" :zoom="land.defaultZoom"
                    class="mapping-map" style="height: calc(100vh - 130px);" :events="events">
                    <el-amap-polyline :path="land.boundary.map(p=>[p.longitude,p.latitude])" stroke-color="#409eff" />
                    <el-amap-polyline v-if="land.boundary.length>=3"
                        :path="[[land.boundary[0].longitude,land.boundary[0].latitude],[land.boundary[land.boundary.length-1].longitude,land.boundary[land.boundary.length-1].latitude]]"
                        stroke-color="#409eff" />
                    <el-amap-marker v-if="land.centerLongitude"
                        :position="[land.centerLongitude,land.centerLatitude]" />
                </el-amap>
            </div>
            <template #footer>
                <div class="float-left">
                    <el-radio-group v-model="op">
                        <el-radio-button :label="1">编辑中心点</el-radio-button>
                        <el-radio-button :label="2">编辑边界</el-radio-button>
                    </el-radio-group>
                    <el-button v-if="op==2" class="ml-2" @click="land.boundary=[];boundaryArea=0">重新绘制</el-button>
                    <span class="ml-2">{{boundaryArea}} 亩</span>
                </div>
                <el-button type="primary" @click="updateLand">确定</el-button>
                <el-button @click="mappingDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <select-region-dialog v-model="selectRegionDialogVisible"
            @select="regionId=$event.id;regionName=$event.name;loadLands(true)" />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isMappingCompleted: false,
                regionId: null,
                regionName: null,
                key: null,
                lands: {},
                landsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                land: {},
                op: 1,
                boundaryArea: 0,
                mappingDialogVisible: false,
                selectRegionDialogVisible: false,
                plugin: [{
                    pName: 'MapType',
                    defaultType: 1,
                    showRoad: true,
                }],
                events: {
                    click: (e) => {
                        if (this.op == 1) {
                            this.land.centerLongitude = e.lnglat.lng;
                            this.land.centerLatitude = e.lnglat.lat;
                        }
                        else if (this.op == 2) {
                            this.land.boundary.push({ longitude: e.lnglat.lng, latitude: e.lnglat.lat });
                            this.calcLandBoundaryArea();
                        }
                    },
                },
            };
        },
        computed: {
            mapCenter() {
                var company = this.$store.state.companies.filter(p => p.id == this.$store.state.companyId)[0];
                return this.land.centerLongitude ? [this.land.centerLongitude, this.land.centerLatitude] : [company.longitude, company.latitude];
            },
        },
        methods: {
            async loadLands(resetPage = false) {
                if (resetPage) {
                    this.landsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Land/GetMappingLands', {
                    params: {
                        companyId: this.$store.state.companyId,
                        isMappingCompleted: this.isMappingCompleted,
                        regionId: this.regionId,
                        key: this.key,
                        pageIndex: this.landsPagination.currentPage - 1,
                        pageSize: this.landsPagination.pageSize,
                    }
                });
                this.lands = response.data;
            },
            landsPaginationCurrentChange(page) {
                this.landsPagination.currentPage = page;
                this.loadLands();
            },
            async calcLandBoundaryArea() {
                let response = await this.$axios.post('/api/Land/CalcLandBoundaryArea', {
                    id: this.land.id,
                    boundary: this.land.boundary,
                });
                this.boundaryArea = response.data;
            },
            showMappingDialog(land) {
                this.land = JSON.parse(JSON.stringify(land));
                this.op = 1;
                this.boundaryArea = 0;
                this.mappingDialogVisible = true;

                this.calcLandBoundaryArea();
            },
            async updateLand() {
                await this.$axios.post('/api/Land/UpdateLandCenterLocation', {
                    id: this.land.id,
                    centerLongitude: this.land.centerLongitude,
                    centerLatitude: this.land.centerLatitude,
                });
                await this.$axios.post('/api/Land/UpdateLandBoundary', {
                    id: this.land.id,
                    boundary: this.land.boundary,
                });
                this.mappingDialogVisible = false;
                this.loadLands();
            },
        },
        created() {
            this.loadLands();
        },
    };
</script>

<style>
    .mapping-map .amap-container {
        cursor: crosshair !important;
    }
</style>